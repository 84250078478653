import { defineStore } from 'pinia'
import noticesQuery from '../apollo/queries/pages/notices.gql'

export const useNoticesStore = defineStore('notices', {
  state: () => ({
    data: {}
  }),
  getters: {
    getNotices: (state) => {
      return state.data.notice ? state.data.notice.data.attributes : null
    },
    getNoticeEventValues: (state) => {
      const values = []

      for (let i = 0; i < state?.data?.noticesEventsValues?.data?.length; i++) {
        values.push(state?.data?.noticesEventsValues?.data[i]?.attributes?.name)
      }

      return state.data.noticesEventsValues ? values : null
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(noticesQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
